Array.prototype.append = (el) ->
  this.push el if this.push?
  this

myElement = document.querySelector("header")
headroom  = new Headroom(myElement,
  offset: 200
)
headroom.init()

jQuery.fn.shake = (interval, distance, times) ->
   interval = 60
   distance = 5
   times = 5
   jTarget = $(this)
   jTarget.css('position', 'relative')

   for i in [0..times]
      jTarget.animate
        left: if i%2==0 then distance else distance*-1
      , interval
   
   return jTarget.animate left: 0, interval
   
document.addEventListener 'DOMContentLoaded', () ->
  return