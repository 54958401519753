var headroom, myElement;

Array.prototype.append = function(el) {
  if (this.push != null) {
    this.push(el);
  }
  return this;
};

myElement = document.querySelector("header");

headroom = new Headroom(myElement, {
  offset: 200
});

headroom.init();

jQuery.fn.shake = function(interval, distance, times) {
  var i, j, jTarget, ref;
  interval = 60;
  distance = 5;
  times = 5;
  jTarget = $(this);
  jTarget.css('position', 'relative');
  for (i = j = 0, ref = times; (0 <= ref ? j <= ref : j >= ref); i = 0 <= ref ? ++j : --j) {
    jTarget.animate({
      left: i % 2 === 0 ? distance : distance * -1
    }, interval);
  }
  return jTarget.animate({
    left: 0
  }, interval);
};

document.addEventListener('DOMContentLoaded', function() {});
